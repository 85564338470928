<template>
  <landing-card
    :title="title"
    icon-type="el-icon-fa-check-square-o"
    :description="description"
    :nextLink="getNextPath"
    :viewButton="inPWA"
    :checkRTL="checkRTL"
  >
    <ul class="desc-list">
      <li :class="checkTextRight">
        <p :class="checkRTL">
          <app-svgtype :type="!checkadblocker"></app-svgtype>
          <app-i18n-wl :code="'entities.checkbrowser.adblockertext'" :lang="lang"/>
        </p>
      </li>
      <li :class="checkTextRight">
        <p :class="checkRTL">
          <app-svgtype :type="isChrome"></app-svgtype>
          <app-i18n-wl :code="'entities.checkbrowser.ischrometext'" :lang="lang" />
        </p>
      </li>
      <li :class="checkTextRight" v-if="chromeVersion">
        <p :class="checkRTL">
          <app-svgtype :type="chromeVersion > 41 ? true : false"></app-svgtype>
          <app-i18n-wl :code="'entities.checkbrowser.browserversiontext'" :lang="lang" />
        </p>
      </li>
      <li :class="checkTextRight">
        <p :class="checkRTL">
          <app-svgtype :type="inPWA"></app-svgtype>
          <app-i18n-wl :code="'entities.checkbrowser.checkPwa'" :lang="lang" />
        </p>
      </li>
    </ul>
  </landing-card>
</template>

<script>
import { mapActions } from 'vuex';
import { i18nWL } from '@/i18n';
import detectAdBlock from './checkadblock';
import svgtype from './svgtype';

function getChromeVersion() {
  var raw = navigator.userAgent.match(
    /Chrom(e|ium)\/([0-9]+)\./,
  );
  return raw ? parseInt(raw[2], 10) : false;
}

export default {
  name: 'online-exam-checkbrowser-page',
  props: ['id', 'lang'],

  components: {
    appSvgtype: svgtype,
  },

  data() {
    return {
      checkadblocker: null,
      isChrome: null,
      chromeVersion: null,
      inPWA: false,
      intId: 0,
    };
  },

  beforeDestroy() {
    clearInterval(this.intId);
  },
  async mounted() {
    detectAdBlock().then((response) => {
      if (response) {
        this.checkadblocker = true;
      } else {
        this.checkadblocker = false;
      }
    });
    this.isChrome =
      !!window.chrome &&
      (!!window.chrome.webstore || !!window.chrome.runtime);

    if (this.isChrome) {
      this.chromeVersion = getChromeVersion();
    }

    this.inPWA = await this.isInPWA(); //ilk seferde calissin sonra delay baslasin
    this.intId = setInterval(async () => {
      this.inPWA = await this.isInPWA();
    },2000);
  },

  computed: {
    checkRTL() {
      //burda exam cekilmedigi icin lokal checkRTL kullanilir
      return this.lang === 'ar' ? ' rtl ' : '';
    },
    checkTextRight() {
      //burda exam cekilmedigi icin lokal checkRTL kullanilir
      return this.lang === 'ar' ? ' text-right ' : '';
    },
    getNextPath() {
      return `/instructions/${this.id}/${this.lang}`;
    },
    description() {
      return i18nWL('entities.checkbrowser.description', this.lang);
    },
    title() {
      return i18nWL('entities.checkbrowser.title', this.lang);
    },
  },
  methods: {
    ...mapActions({
      isInPWA: 'onlineExam/main/isInPWA',
    }),
  }
};
</script>

<style>
ul.desc-list > li.text-right {
  text-align: right;
}
</style>

<style scoped>
ul {
  padding-inline-start: 0;
}

ul li {
  list-style-type: none;
}

ul li p {
  display: flex;
  justify-content: center;
}

p.rtl div {
  margin-left: 1rem;
}
</style>
